import { Injectable } from '@angular/core';
import { ExportInterface } from '../../domain/models/export.interface';
import { DateUtil } from '../utils/date/date.util';
import md5 from 'md5';
import {ToastrService} from './toastr.service';
import {APP_CONFIG} from '../../../config/app.server.config';

export interface ExportItemInterface
{
    createdAt: number;
    path: string;
    status: keyof typeof ExportStatusEnum;
    title: string;
    key: number;
}

export enum ExportStatusEnum
{
    done = 'done',
    inProgress = 'inProgress',
    // error = 'error',
}

@Injectable({
    providedIn: 'root',
})

export class ExportService
{
    readonly LOCAL_STORAGE_KEY = 'exportResultsService';

    /**
     * @deprecated
     */
    private itemsMap: Map<number, ExportItemInterface> = new Map();

    constructor(
        public toastrService: ToastrService,
    )
    {
        try {
            this.itemsMap = this.getLocalStorage();
            this.updateLocalStorage(); // save removed old items
            for (const item of this.items) {
                this.checkPath(item).then(this.updateLocalStorage);
            }
        } catch (e) {
            console.log('Error while parsing localStorage Export results', e);
        }
    }

    /**
     * @deprecated
     */
    get items(): ExportItemInterface[]
    {
        return Array.from(this.itemsMap.values());
    }
    get itemsCount(): number
    {
        return this.itemsMap.size;
    }

    get hasDone(): boolean
    {
        return this.items.some(item => item.status === ExportStatusEnum.done);
    }

    get canAdd(): boolean
    {
        return this.itemsCount < 10;
    }

    preCheck = () => {
        if (!this.canAdd) {
            this.toastrService.errorNotify('You can\'t add more than 10 Exports');
            throw new Error('You can\'t add more than 10 Exports');
        }
    };

    /**
     * @deprecated
     */
    add = (response: ExportInterface): ExportItemInterface => {
        const { path, export_name } = response;
        const createdAt = Date.now();
        const key = md5(createdAt + path);
        const exportItem: ExportItemInterface = {
            createdAt,
            key,
            path,
            status: ExportStatusEnum.inProgress,
            title: export_name,
        };
        this.itemsMap.set(key, exportItem);
        this.updateLocalStorage();
        this.checkPath(exportItem);
        this.toastrService.successNotify(`${export_name || 'Export'} was successfully added to queue`);
        return exportItem;
    };

    /**
     * @deprecated
     */
    update = (key: number, status: keyof typeof ExportStatusEnum)=> {
        if (!this.itemsMap.has(key)) {
            this.toastrService.errorNotify('Export not found');
            return;
        }
        const exportItem = this.itemsMap.get(key);
        exportItem.status = status;
        this.toastrService.successNotify(`${exportItem.title || 'Export'} was successfully created and ready to download`);
        this.updateLocalStorage();
    };

    /**
     * @deprecated
     */
    remove = (key: number) => {
        this.itemsMap.delete(key);
        this.updateLocalStorage();
    };

    private updateLocalStorage = (): void => {
        if (!this.itemsMap.size) {
            localStorage.removeItem(this.LOCAL_STORAGE_KEY);
            return;
        }
        localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.items));
    };

    private getLocalStorage = (key = this.LOCAL_STORAGE_KEY): Map<number, ExportItemInterface> => {
        const ls = localStorage.getItem(key);
        return new Map(ls
            ? JSON.parse(localStorage.getItem(key))
                .filter((value: ExportItemInterface) => DateUtil.addDays(new Date(value.createdAt), 1) > new Date())
                .map((value: ExportItemInterface) => ([value.key, value]))
            : []
        );
    };

    /**
     * @deprecated
     */
    private checkPath = async (item: ExportItemInterface) => {
        if (item.status === ExportStatusEnum.done) {
            return;
        }
        const url = `${APP_CONFIG.MAIN_SERVER}${item.path}`;
        const response = await this.fileExistOnServer(url);
        if (response.status === 200) {
            item.status = ExportStatusEnum.done;
            return;
        }
        const timer = setInterval(async () => {
            const delayResponse = await this.fileExistOnServer(url);
            if (delayResponse.status === 200) {
                item.status = ExportStatusEnum.done;
                clearInterval(timer);
            }
        }, 30 * 1000); // 30 seconds
    };

    /**
     * @deprecated
     */
    private fileExistOnServer = async (url: string): Promise<any> => {
        try {
            const response = await fetch(url, {
                method: 'HEAD',
                headers: new Headers({
                    'Content-Type': 'text/plain'
                })
            });
            return Promise.resolve(response);
        } catch (e) {
            return Promise.resolve(e);
        }
    }
}
