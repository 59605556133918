import { Component, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from '../../services/core.service';
import { ExportItemInterface, ExportService, ExportStatusEnum } from '../../services/export.service';
import {IconUrls} from '../../../constants/icon-urls';

/**
 * @deprecated
 */
@Component({
    selector: 'app-export-results',
    templateUrl: './export-results.component.html',
    styleUrls: ['./export-results.component.scss'],
    providers: [NgbDropdown],
})
export class ExportResultsComponent
{
    @ViewChild('exportResultsDropdown', {static: true}) dropdown: NgbDropdown;
    protected readonly ExportStatusEnum = ExportStatusEnum;

    constructor(
      public iconUrls: IconUrls,
        public exportResultsService: ExportService,
        private coreService: CoreService,
    )
    {
    }

    trackByFunction = (index, item: ExportItemInterface): number => item.key;

    download = ({path}: ExportItemInterface) => this.coreService.downloadByPath(path, '');
}
