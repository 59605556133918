import {Injectable} from '@angular/core';
import {PriceListRepository} from '../../infrastructure/repositories/price-list.repository';
import {FilterQueryBuilder} from '../utils/query-builder/filter-query-builder';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Warehouse} from '../../domain/entity/warehouse/warehouse.entity';
import {PriceTotalsInterface} from '../../infrastructure/response/offer/price-totals.interface';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';
import {CreateOfferRequest, UpdateOfferRequest} from '../../infrastructure/requests/offer.request';
import {StatusMessageInterface} from '../../domain/models/status-message.interface';
import {OfferResponse} from '../../infrastructure/response/offer/offer.response';
import {AuthService} from './auth.service';
import {SellerService} from './seller.service';

@Injectable({
    providedIn: 'root'
})
export class PriceListService {

    processExport= this.priceListRepository.processExport;
    private _sendEmail: string;
    constructor(
        private priceListRepository: PriceListRepository,
        private authService: AuthService,
        private sellerService: SellerService,
    ) {
    }

    get sendEmail(): string {
        return this.authService.getAdminEmail() ?? this.sellerService.currentSeller.email;
    }

    loadPrices = <T>(
        queryBuilder: FilterQueryBuilder,
        warehouse: Warehouse,
        facadeConstructor: { build: (item: OfferResponse, warehouse: Warehouse) => T }
    ): Observable<T[]> => this.priceListRepository.fetchPrices(queryBuilder, warehouse.id).pipe(
        map((response) => response.map((o) => facadeConstructor.build(o, warehouse))));

    loadPriceTotals = (queryBuilder: FilterQueryBuilder, warehouseId: string): Observable<PriceTotalsInterface> =>
        this.priceListRepository.fetchPriceTotals(queryBuilder, warehouseId);

    createOffer = (offer: CreateOfferRequest): Observable<CreateStatusMessageInterface> => this.priceListRepository.createOffer(offer);

    disable = (offerIds: string[], warehouseId: string): Observable<StatusMessageInterface> => this.priceListRepository.disable(offerIds, warehouseId);

    enable = (offerIds: string[], warehouseId: string): Observable<StatusMessageInterface> => this.priceListRepository.enable(offerIds, warehouseId);

    link = (offerId: string, partId: number): Observable<StatusMessageInterface> => this.priceListRepository.link(offerId, partId);

    unlink = (offerIds: string[], warehouseId: string): Observable<StatusMessageInterface> => this.priceListRepository.unlink(offerIds, warehouseId);

    remove = (offerIds: string[], warehouseId: string): Observable<StatusMessageInterface> => this.priceListRepository.remove(offerIds, warehouseId);

    ignore = (offerIds: string[], warehouseId: string): Observable<StatusMessageInterface> =>
        this.priceListRepository.ignore(offerIds, warehouseId, true);

    unignore = (offerIds: string[], warehouseId: string): Observable<StatusMessageInterface> =>
        this.priceListRepository.ignore(offerIds, warehouseId, false);

    addPartAltNumber = (partId: number, altNumber: string): Observable<StatusMessageInterface> => this.priceListRepository.addPartAltNumber(partId, altNumber);

    updateOffer = (offer: UpdateOfferRequest, id: string): Observable<StatusMessageInterface> => this.priceListRepository.updateOffer(offer, id);

    massAction = (
        warehouseId: string,
        type: 'enable' | 'disable' | 'unlink' | 'remove' | 'ignore' | 'unignore',
        queryBuilder: FilterQueryBuilder
    ): Observable<StatusMessageInterface> =>
        this.priceListRepository.massAction(warehouseId, type === 'remove' ? 'delete' : type, queryBuilder, this.sendEmail);
}
