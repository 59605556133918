import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {PriceListService} from '../../services/price-list.service';
import {FilterQueryBuilder} from '../../utils/query-builder/filter-query-builder';
import {OfferFacade} from '../../facades/offer/offer.facade';
import {Subscription} from 'rxjs';
import {MassActionService} from '../../services/mass-action.service';
import {ColumnInterface, ConfigInterface, FindInListWithGroupsInterface} from '../../components/th/th.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PriceDetailsComponent} from './price-details/price-details.component';
import {CONSTANTS} from '../../../constants/constants';
import {Warehouse} from '../../../domain/entity/warehouse/warehouse.entity';
import {SellerService} from '../../services/seller.service';
import {Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../services/breadcrumbs/breadcrumbs.service';
import {IconUrls} from '../../../constants/icon-urls';
import {filter, finalize, take} from 'rxjs/operators';
import {PriceTotalsInterface} from '../../../infrastructure/response/offer/price-totals.interface';
import {ProcessPriceComponent} from './process-price/process-price.component';
import {ToastrService} from '../../services/toastr.service';
import {GridHelper} from '../../utils/grid-helper/grid-helper';
import {WarehouseService} from '../../services/warehouse.service';
import {MassActionGroupInterface} from '../../../domain/models/mass-action-group.interface';
import {LinkOfferComponent} from './link-offer/link-offer.component';
import {ConfirmService} from '../../services/confirm-modal.service';
import {ExportService} from '../../services/export.service';

const DEFAULT_FILTERS: { [key: string]: ColumnInterface } = {
    brand: {
        filter: {value: '', applied: false, name: 'brand', type: 'text'},
    },
    number: {
        filter: {value: '', applied: false, name: 'number', type: 'text'},
    },
    partNumber: {
        filter: {value: '', applied: false, name: 'partNumber', type: 'text'},
    },
    name: {
        filter: {value: '', applied: false, name: 'name', type: 'text'},
    },
    price: {
        filter: {value: '', applied: false, name: 'price', type: 'numberRange', usePice: true},
        sort: {name: 'price', value: null, applied: false},
    },
    purchasePrice: {
        filter: {value: '', applied: false, name: 'purchasePrice', type: 'numberRange', usePice: true},
        sort: {name: 'purchasePrice', value: null, applied: false},
    },
    mrp: {
        filter: {value: '', applied: false, name: 'mrp', type: 'numberRange', usePice: true},
        sort: {name: 'mrp', value: null, applied: false},
    },
    online: {
        filter: {value: '', applied: false, name: 'online', type: 'select'},
    },
    enabled: {
        filter: {value: '', applied: false, name: 'enabled', type: 'select'},
    },
    lastImported: {
        filter: {value: '', applied: false, name: 'lastImported', type: 'dateRange'},
        sort: {name: 'lastImported', value: null, applied: false},
    },
    linked: {
        filter: {value: '', applied: false, name: 'linked', type: 'select'},
    },
    stockValidTill: {
        filter: {value: '', applied: false, name: 'stockValidTill', type: 'dateRange'},
    },
    ignored: {
        filter: {value: '', applied: true, name: 'ignored', type: 'select'},
    }
};

@Component({
    selector: 'app-price-list',
    templateUrl: './price-list.component.html',
    styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit, OnDestroy {

    public readonly queryBuilder: FilterQueryBuilder;

    public cols: { [key: string]: ColumnInterface } = Object.assign({}, DEFAULT_FILTERS);
    public gridLoader: Subscription | boolean;
    public loader: Subscription | boolean;
    public loaderTotals: Subscription | boolean;
    public prices: OfferFacade[] = [];
    public pricesLoaded: boolean;
    public showErrorOnTotals: boolean;
    public selectedWarehouse: Warehouse = null;
    public totals: PriceTotalsInterface;
    public warehouses: Warehouse[];
    public warehouseList: FindInListWithGroupsInterface[];
    public useMassActionByFilters: boolean;

    private passedWarehouse: string;
    private pendingRequest: Subscription;
    private warehouseSelectedSubscription: Subscription;

    constructor(
        public iconUrls: IconUrls,
        public massActionService: MassActionService,
        private breadcrumbsService: BreadcrumbsService,
        private modalService: NgbModal,
        private priceListService: PriceListService,
        private sellerService: SellerService,
        private titleService: Title,
        private toastrService: ToastrService,
        private warehouseService: WarehouseService,
        private cd: ChangeDetectorRef,
        public confirmService: ConfirmService,
        private exportService: ExportService,
    ) {
        const title = 'Listing - Price List';
        this.titleService.setTitle(`${title} · Seller Cabinet`);
        this.breadcrumbsService.init(title);
        this.queryBuilder = new FilterQueryBuilder(
            {
                filterSet: [
                    ...Object.values(this.cols).map((c) => c.filter.name),
                    'priceFrom',
                    'priceTo',
                    'purchasePriceFrom',
                    'purchasePriceTo',
                    'mrpFrom',
                    'mrpTo',
                    'lastImportedFrom',
                    'lastImportedTo',
                    'stockValidTillFrom',
                    'stockValidTillTo',
                ],
                limit: 10
            },
        );
        setTimeout(() => {
            this.cols.ignored.filter.value = false;
            this.queryBuilder.handleFilter('ignored', false);
        });
        this.warehouseSelectedSubscription = this.warehouseService.warehouseChanged$
            .pipe(
                filter((v) => !!v)
            )
            .subscribe(value => {
                this.passedWarehouse = value;
                this.selectWarehouse();
            });
    }

    get isSmallDisplay(): boolean {
        return window.innerWidth <= CONSTANTS.MAX_POPUP_SCREEN_SM;
    }

    get fakeGroup(): MassActionGroupInterface
    {
        return {
            id: 'fakeGroupID',
            items: this.prices
        };
    }

    get offlineTotals(): number {
        return this.totals.total - this.totals.online;
    }

    ngOnInit(): void {
        this.loader = true;
        this.sellerService.$currentSeller.pipe(
            take(1),
            finalize(() => {
            this.loader = false;
        })).subscribe((seller) => {
            this.massActionService.init();
            this.warehouses = seller?.warehouses.filter((w) => !w.fulfilledStock).sort((a, b) => a?.name > b?.name ? 1: -1);
            if (this.warehouses?.length) {
                this.selectWarehouse();
            }
            this.warehouseList = this.sellerService.groupWarehouses(this.warehouses);
            this.cd.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.pendingRequest?.unsubscribe();
        this.warehouseSelectedSubscription?.unsubscribe();
    }

    public buildPriceListGrid(): void {
        this.pricesLoaded = true;

        this.pendingRequest?.unsubscribe();

        this.pendingRequest = this.gridLoader = this.priceListService.loadPrices(this.queryBuilder, this.selectedWarehouse, OfferFacade)
            .pipe(
                finalize(() => this.gridLoader = false),
            )
            .subscribe(
                (prices) => {
                    this.prices = prices;
                });
    }

    link(facade?: OfferFacade) {
        const modalRef = this.modalService.open(LinkOfferComponent, {
            size: 'xl',
            scrollable: true,
        });
        modalRef.componentInstance.offerFacade = facade;
        modalRef.result.then(() => {
            this.buildPriceListGrid();
        }, () => {});
    }

    offerAction(type: 'enable' | 'disable' | 'unlink' | 'remove' | 'ignore' | 'unignore', offer?: OfferFacade) {
        this.confirmService.confirm({
            title: `Accept "${type}" action`,
            message: `Are you sure you want to <b>${type.toUpperCase()}</b>${offer?.fullName ? ' Offer: ' + offer.fullName: ''}?`,
        }).then(() => {
            this._offerAction(type, offer);
        }, () => {});
    }

    public loadTotals(): void {
        this.loaderTotals = this.priceListService.loadPriceTotals(this.queryBuilder, this.selectedWarehouse.id).pipe(
                finalize(() => this.loaderTotals = false),
            ).subscribe((response: PriceTotalsInterface) => {
                this.totals = response;
            }, () => {
                this.showErrorOnTotals = true;
            });
    }

    applyFilters($event?: number): void {
        this.queryBuilder.changePageNum($event || 1);

        this.massActionService.init();
        this.buildPriceListGrid();
        this.loadTotals();
    }

    public onChangePagination($event?: number): void {
        this.showErrorOnTotals = false;
        this.applyFilters($event);
    }

    public openDetail(price: OfferFacade): void {
        if (!this.isSmallDisplay) {
            return;
        }
        const modalRef = this.modalService.open(PriceDetailsComponent);
        modalRef.componentInstance.price = price;
    }

    public processPrice(offerFacade?: OfferFacade): void {
        const modalRef = this.modalService.open(ProcessPriceComponent);
        if (offerFacade) {
            modalRef.componentInstance.offerFacade = offerFacade;
        }
        modalRef.componentInstance.warehouse = this.selectedWarehouse;
        modalRef.result.then((type) => {
            if (type === 'success') {
                this.onChangePagination();
            }
        }, () => {
        });
    }

    sort(sortCol: ConfigInterface): void {
        GridHelper.ClearSorting(this.cols, sortCol);
    }

    processExport(type: 'all' | 'unlinked' | 'filtered'): void {
        this.exportService.preCheck();
        this.loader = this.priceListService.processExport(type, this.selectedWarehouse.id, this.queryBuilder).pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe(() => {
            this.toastrService.successNotify('Export successfully added to queue. And will be sent to your email.');
        });
    }

    warehousesChangedHandler(): void
    {
        GridHelper.ClearSorting(this.cols);
        GridHelper.ClearFilters(this.cols);
        this.queryBuilder.clearFilters();
        this.queryBuilder.handleFilter('warehouse', this.selectedWarehouse.id);
        this.onChangePagination();
    }

    toggleMassAction() {
        this.useMassActionByFilters = !this.useMassActionByFilters;
    }

    private selectWarehouse(): void {
        if (!this.warehouses?.length) {
            return;
        }

        const passedWarehouse = this.warehouses?.find((w) => w.id === this.passedWarehouse);
        this.selectedWarehouse = passedWarehouse ?? this.warehouses[0];
        this.buildPriceListGrid();
        this.loadTotals();
    }

    private _offerAction(action: 'enable' | 'disable' | 'unlink' | 'remove' | 'ignore' | 'unignore', offer?: OfferFacade) {
        const offerIds: string[] = offer?.id
            ? [offer.id]
            : this.massActionService.getResult;
        if (!offerIds.length && !this.useMassActionByFilters) {
            this.toastrService.errorNotify('First you need to select a product');
            return;
        }
        let request = this.priceListService[action](offerIds, this.selectedWarehouse.id);
        if (this.useMassActionByFilters && !offer) {
            request = this.priceListService.massAction(this.selectedWarehouse.id, action, this.queryBuilder);
        }
        this.loader = request.pipe(finalize(() => {
            this.loader = false;
        })).subscribe((response) => {
            this.toastrService.successNotify(response);
            this.massActionService.init();
            if(!this.useMassActionByFilters) {
                this.onChangePagination();
            }
        });
    }
}
