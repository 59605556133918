import {Injectable} from '@angular/core';
import {AbstractRepository} from './abstract.repository';
import {FilterQueryBuilder} from '../../application/utils/query-builder/filter-query-builder';
import {Observable} from 'rxjs';
import {OfferResponse} from '../response/offer/offer.response';
import {PriceTotalsInterface} from '../response/offer/price-totals.interface';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';
import {StatusMessageInterface} from '../../domain/models/status-message.interface';
import {CreateOfferRequest, UpdateOfferRequest} from '../requests/offer.request';
import {JobMessageInterface} from "../../domain/models/job-message.interface";

@Injectable({
    providedIn: 'root'
})
export class PriceListRepository extends AbstractRepository {

    readonly offerUrl = 'srm/offer';
    readonly partUrl = 'srm/part';

    fetchPrices = (queryBuilder: FilterQueryBuilder, warehouseId: string): Observable<OfferResponse[]> =>
        this.httpClient.post<OfferResponse[]>(
            this.buildUrl(`${this.offerUrl}/grid/${warehouseId}`),
            queryBuilder.getRequestObject(),
        );

    fetchPriceTotals = (queryBuilder: FilterQueryBuilder, warehouseId: string): Observable<PriceTotalsInterface> =>
        this.httpClient.post<PriceTotalsInterface>(this.buildUrl(`${this.offerUrl}/totals/${warehouseId}`), queryBuilder.getRequestObject());

    createOffer = (offer: CreateOfferRequest): Observable<CreateStatusMessageInterface> =>
        this.httpClient.post<CreateStatusMessageInterface>(this.buildUrl(this.offerUrl), this.prepareOfferRequest(offer));

    updateOffer = (offer: UpdateOfferRequest, id: string): Observable<StatusMessageInterface> => this.httpClient.put<StatusMessageInterface>(
        this.buildUrl(`${this.offerUrl}/${id}/update`), this.prepareOfferRequest(offer)
    );

    processExport = (
        type: 'all' | 'unlinked' | 'filtered',
        warehouseId: string,
        queryBuilder: FilterQueryBuilder,
        timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
    ): Observable<JobMessageInterface> => {
        const {filters, sorting} = queryBuilder.getRequestObject();
        return this.httpClient.post<JobMessageInterface>(this.buildUrl(`${this.offerUrl}/export/${warehouseId}`), {
            filters,
            sorting,
            timezone,
            type,
        }, {params: {timezone}});
    };


    enable = (offers: string[], warehouseId: string): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.offerUrl}/enable/${warehouseId}`), {offers});

    disable = (offers: string[], warehouseId: string): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.offerUrl}/disable/${warehouseId}`), {offers});

    link = (id: string, part_id: number): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.offerUrl}/${id}/link`), {part_id});

    unlink = (offers: string[], warehouseId: string): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.offerUrl}/unlink/${warehouseId}`), {offers});

    remove = (offers: string[], warehouseId: string): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.offerUrl}/remove/${warehouseId}`), {offers});

    ignore = (offers: string[], warehouseId: string, value: boolean): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.offerUrl}/ignore/${warehouseId}`), {offers, value});

    addPartAltNumber = (partId: number, number: string): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`${this.partUrl}/${partId}/alt-number`), {number});

    massAction = (
        warehouseId: string,
        type: 'enable' | 'disable' | 'unlink' | 'delete' | 'ignore' | 'unignore',
        queryBuilder: FilterQueryBuilder,
        email: string,
    ): Observable<StatusMessageInterface> => this.httpClient.post<StatusMessageInterface>(
        this.buildUrl(`${this.offerUrl}/mass-action/${warehouseId}`), {
            type,
            email,
            filters: queryBuilder.getRequestObject().filters
        });

    private prepareOfferRequest = (offer: CreateOfferRequest | UpdateOfferRequest) => {
        const {partNumber, brand, name, price, purchasePrice, mrp, qty, enabled, dispatchDays, ignored} = offer;
        const result = {
            number: partNumber,
            brand,
            name,
            price: price * 100,
            purchase_price: purchasePrice * 100,
            mrp: mrp * 100,
            qty,
            ignored: ignored ?? false,
            enabled: enabled ?? false,
            dispatch_days: dispatchDays,
        };
        if ('warehouse' in offer) {
            return Object.assign(result, {warehouse: offer.warehouse});
        }
        return result;
    };
}
